.fModal {
  max-width: 500px !important;
  border-radius: 13px !important;
  max-height: 80% !important;

  @media (max-width: 500px) {
    max-width: 100% !important;
    max-height: calc(100% - 40px) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.fModalOverlay {
  align-items: center;

  @media (max-width: 500px) {
    align-items: flex-end;
  }
}

.content {
  width: 100%;
  height: 100%;

  &__column {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  &__cardIcon {
    height: 56px;
  }

  &__title {
    margin-top: 12px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #000000;
  }

  &__text {
    margin: 22px 0;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #7B7B94;
  }

  &__inputContainer {
    width: 250px;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border: 1px solid #B4B4D2;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 12px 60px 12px 32px;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      color: #000000;
    }

    p {
      position: absolute;
      left: 12px;
      padding-top: 1px;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      color: #000000;
    }

    span {
      position: absolute;
      right: 12px;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #B4B4D2;
    }
  }

  &__btn {
    background-color: #413E93;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    height: 54px;
    min-width: 250px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: darken(#413E93, 20);
    }

    &:disabled {
      background-color: #AFAFAF;
    }
  }

}
