.paydbtns {
    width: 100%;
}
.spase {
    height: 5px;
}
.loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
}
.loaderContainer > div {
    height: 40px;
}
.loaderContainer > div > div {
    top: 16px;
}
.paybtn button {
    width: 100%;
    justify-content: center;
}
.vipbtns {
    display: flex;
    margin: 0 -6px;
    justify-content: space-between;
}
.vipbtn {
    flex: 1;
    margin: 0 6px;
    margin-top: 12px;
    height: 40px;
}
.vipbtn button {
    width: 100%;
    box-shadow: none;
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    /*cursor: pointer;*/
    box-sizing: border-box;
    padding: 0 8px;
}

.vipbtn button > div:nth-child(2) {
    flex: 1;
    max-width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
}

.vipbtn button > div:nth-child(2) > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vipbtnActive button:not([disabled]) {
    cursor: pointer;
}
.vipbtnActive button:disabled {
    opacity:0.6
}

.vipbtnActive button > div {
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: break-all;
    line-break: auto;
    hyphens: manual;
}

.vipbtnActive button:hover {
    background: rgba(0, 0, 0, 0.6);
}

.vipbtnActive button:active {
    background: rgba(0, 0, 0, 0.7);
}

.vipbtn button svg {
    margin-right: 15px;
    padding-top: 2px;
}
.onevip.onevip .vipbtn {
    width: 100%;
}
@media (max-width: 1023px) {
    .paybtn button {
        font-size: 15px;
    }
    .paydbtns {
        position: relative;
        width: 100%;
        margin-bottom: 15px;
    }
    .vipbtns {
        flex-direction: column;
        width: 100%;
    }
    .vipbtn {
        width: 100%;
        margin-top: 8px;
    }
    .vipbtn button {
        background: #e9e8f3;
        color: #413e93;
    }
    /*.vipbtn button:hover {*/
    /*    background: #e3e1f5;*/
    /*}*/
    /*.vipbtn button:active {*/
    /*    background: #c1bfcb;*/
    /*}*/
    .vipbtn button svg path {
        fill: #413e93;
    }
    .onevip.onevip {
        width: 100%;
        margin-bottom: 15px;
    }
}

.trial {
    padding: 20px;
    border: 1px solid #ffffff7a;
    box-sizing: border-box;
    border-radius: 8px;

    @media (max-width: 1023px) {
        padding: 0;
        margin-bottom: 12px;
    }

    &__column {
        display: flex;
        flex-direction: column;
    }

    button {
        height: 44px;
        background: linear-gradient(258.65deg, #ffdb8f -5.62%, #5a3d00 94.86%);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        padding: 0 20px;
        cursor: pointer;

        @media (max-width: 500px) {
            padding: 0 8px;
            flex-direction: column;
            justify-content: center;
        }

        &:hover {
            background: linear-gradient(262.62deg, #413e93 39.06%, #8b7ac6 84.36%);
        }

        .free {
            font-family: "SF Pro Display", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #ffffff;

            @media (max-width: 500px) {
                font-size: 14px;
                line-height: 14px;
            }
        }

        .then {
            font-family: "SF Pro Display", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #ffffff;

            @media (max-width: 500px) {
                font-size: 11px;
                line-height: 11px;
            }
        }
    }
}
