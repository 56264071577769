.modal {
  background-color: white;
  outline: none;
  padding: 16px !important;
  display: flex !important;
  border-radius: 13px !important;
  margin: 0;
  width: 620px;
  box-sizing: border-box;
}

.overlay {
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  overflow: hidden;
}

.header {
  position: relative;
  min-height: 36px;
}

.heated__title {
  font-size: 18px;
  font-weight: 500;
}

.modcont {
  height: 100%;
}

.closemodal {
  position: absolute;
  right: -7px;
  top: -7px;
  cursor: pointer;
}

.botbtn > div {
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.botbtn.cardbtn button svg {
  margin-top: 8px;
  margin-left: 10px;
}

.hint {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
}

.hintText {
  font-size: 22px;
  font-weight: 600;
  max-width: 80%;
  text-align: center;
  margin-top: 10px;
}

.errorLogCode {
  font-size: 22px;
  font-weight: 400;
  max-width: 80%;
  text-align: center;
  margin-top: 4px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  max-width: 80%;
  text-align: center;
  margin-top: 12px;
}

.warningIc,
.oopsIcon {
  width: fit-content;
}

.warningIc svg path {
  fill: #413e93;
}

.billLink {
  text-decoration: none;
  display: inline-block;
}

.modbot {
  text-align: center;
}

@media (max-width: 1023px) {
  .hint {
    padding-bottom: 75px;
    padding-top: 75px;
  }
  .heated__title {
    padding-right: 20px;
  }
  .hintText {
    width: 80%;
  }
  .container {
    padding: 0;
  }
  .closemodal {
    top: 0;
    right: 0;
  }
  .hintText {
    margin-top: 20px;
  }
  .botbtn.hintbtn button {
    width: auto;
  }
  .modal {
    border-radius: 24px !important;
  }
}

.payment {
  margin-top: 20px;
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.phoneBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.phoneTit {
  font-weight: 600;
  font-size: 18px;
  width: 170px;
  margin-left: 22px;
}

.paymentInp {
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 20px);
  text-align: center;
  height: 44px;
  box-shadow: none;
  border-radius: 9px;
  outline: none;
  border: 1px solid #d1d1e4;
  font-weight: 600;
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  color: #2a277c;
  margin-bottom: 20px;
}

.paymentInp:focus {
  border-color: #413e93;
}

.paymentInp::placeholder {
  font-family: "SF Pro Display", sans-serif;
  color: #8989ad;
  font-size: 16px;
  font-weight: 600;
}

.resendText {
  font-weight: 500;
  font-size: 15px;
  color: #7e7ea5;
  text-align: center;
  margin-bottom: 30px;
}

.payment button {
  width: 100%;
  justify-content: center;
}

.preload {
  position: fixed;
  z-index: 111;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  top: 0;
  left: 0;
  border-radius: 13px;
}

.preload div {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

@media (max-width: 1023px) {
  .payment {
    width: 100%;
  }
}

.addCard {
  padding: 16px;
  box-sizing: border-box;
  overflow-y: auto;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;

  form {
    min-height: 430px;
  }

  @media (max-height: 430px) {
    justify-content: unset;
  }
}

.fullscreen {
  width: 100%;
  border-radius: 0 !important;
  height: calc(100% - 78px);
  margin-top: 78px;
  margin-bottom: 0 !important;
  padding: 16px 0 !important;

  .closemodal {
    top: 0;
    right: 16px;
  }

  @media (max-width: 1280px) {
    height: calc(100% - 53px);
    margin-top: 53px;
  }
  @media (max-width: 1023px) {
    height: calc(100% - 50px);
    margin-top: 50px;
  }
}
