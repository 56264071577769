.addCard {
  width: 100%;
  position: relative;
  min-height: 250px;

  &__youNeedConfirmEmail{
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    font-family: "SF Pro Display", sans-serif;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;

    &_link{
      width: fit-content;
      margin-top: 8px;
      background: linear-gradient(270deg, #423a95 0%, #594cac 100%);
      border: none;
      box-sizing: border-box;
      border-radius: 8px;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      height: 44px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover{
        background: linear-gradient(270deg, darken(#423a95, 20) 0%, darken(#594cac, 20) 100%);
      }

      &:disabled{
        background-color: #AFAFAF;
      }
    }
  }

  form {
    width: 100%;
  }

  &__invalidCard {
    margin-top: 24px;
    padding: 12px;
    border-radius: 10px;
    background: #CAC4DE4D;
    display: flex;
    align-items: center;

    & > div {
      height: 36px;
      width: 36px;
      margin-right: 12px;
      background: #CF142B;
      box-shadow: 2px 4px 10px rgba(113, 19, 19, 0.12);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #FFFFFF;
        }
      }
    }

    span {
      flex: 1;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
    }
  }
}

.overCard {
  max-width: 475px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
}

.frontCard {
  width: 280px;
  height: 160px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 13px;
  z-index: 1;
  position: relative;
  padding: 20px 35px;
}

.backCard {
  width: 350px;
  height: 210px;
  background: #eae9f2;
  border-radius: 13px;
  position: absolute;
  right: 0;
  top: 40px;
}

.frontTit {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}

.frontTitEx {
  margin-bottom: 0;
  width: 80px;
}

.input {
  position: relative;
}

.input input {
  border: 1px solid #b4b4d2;
  outline: none;
  width: calc(100% - 20px);
  height: 32px;
  display: flex;
  border-radius: 8px;
  background: white;
  padding: 0 10px;
  font-size: 13px;
  margin-bottom: 12px;
  font-weight: bold;
}

.input input:focus {
  border-color: #413e93;
}

.input input::placeholder {
  color: #a5a5ba;
  font-weight: 500;
}

.input .cardType {
  position: absolute;
  right: 8px;
  top: 0;
  height: 34px;
  display: flex;
  align-content: center;
}

.input .cardType svg {
  margin: auto 0;
}

.input_error input {
  border: 1px solid #CF142B !important;
}

.expiration {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expSelects {
  display: flex;
  align-items: center;
}

.slash {
  color: #0e1114;
  font-size: 13px;
  padding-left: 7px;
  padding-right: 7px;
  font-weight: 500;
}

.sel {
  height: 34px !important;
  width: 85px;
}

.sel [class$="-singleValue"] {
  font-size: 13px;
}

.sel [class$="-ValueContainer"] {
  padding-left: 12px;
}

.sel [class$="Input"] input {
  display: none;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: #000 !important;
}

.sel [class$="-IndicatorsContainer"] {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

.sel [class$="-indicatorContainer"] {
  display: flex;
  justify-content: flex-end;
  margin-right: 0 !important;
  width: 100%;
  padding: 5px 8px 5px 0;
}

.sel [class$="-placeholder"] {
  color: #a5a5ba;
  font-size: 13px;
  font-weight: 500;
}

.sel [class$="-option"] {
  height: 32px !important;
  font-size: 13px !important;
  color: black !important;
}

.sel [class$="-singleValue"] {
  font-size: 13px !important;
  font-weight: bold !important;
  color: black !important;
}

.backLine {
  height: 36px;
  background: #888799;
  margin-top: 24px;
}

.cvc {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.cvcBlock {
  display: flex;
  margin-right: 25px;
  flex-direction: column;
}

.cvcTit {
  font-size: 13px;
  font-weight: 500;
  color: black;
  margin-bottom: 12px;
}

.cvcInp {
  width: 70px;
}

.cvcInp input {
  width: calc(100% - 22px);
  height: 32px;
  border: 1px solid #8f8fb2;
  background: transparent;
  outline: none;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  font-weight: bold;
}

.cvcText {
  font-size: 12px;
  color: #888799;
  width: 80px;
  margin-top: 12px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .sel {
    width: 75px;
  }
  .addCard {
    display: flex;
    flex-direction: column;
  }
  .frontCard {
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 12px;
    height: 140px;
  }
  .backCard {
    width: calc(100%);
    position: relative;
    top: auto;
    right: auto;
    height: 180px;
  }
  .backLine {
    top: 24px;
    position: absolute;
    width: 100%;
    margin-top: 0;
  }
  .cvc {
    justify-content: flex-start;
    margin-top: 0;
  }
  .cvcBlock {
    flex-direction: row;
    margin-top: 75px;
    margin-right: 0;
    display: block;
    margin-left: 30px;
  }
  .cvcTit {
    display: block;
    margin-bottom: 4px;
  }
  .cvcText {
    margin-top: 0;
    margin-left: 15px;
    width: 100px;
  }
  .cvcOverInp {
    display: flex;
    align-items: center;
  }
  .expiration {
    align-items: center;
  }
}

.addCardBtn {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.addCardBtn svg {
  margin-top: 8px;
}

@media (max-width: 600px) {
  .overCard {
    max-width: 290px;
    width: 100%;
  }
  .addCardBtn {
    margin-top: 12px;
  }
}

@media (max-width: 350px) {
  .sel {
    width: 70px;
  }
  .frontCard {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
  }
}

.payBlock {
  margin: 20px 0;
}

.payBlock.payInputs {
  display: flex;
  margin-top: 80px;
  margin-bottom: 0;
  justify-content: center;
}

.payInputs div {
  width: auto;
  margin-left: 12px;
  max-width: 220px;
}

.payInputs div:first-child {
  margin-left: 0;
}

.payInputs div button {
  min-width: 140px;
  justify-content: center;
}

@media (max-width: 600px) {
  .payBlock.payInputs {
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .payInputs div {
    margin-left: 0;
    max-width: 100%;
  }
}

.loader {
  height: 45px;

  div {
    top: 16px;
  }
}
