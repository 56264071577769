.videoItem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videoContainer {
    width: 100%;
    display: block;
    max-height: 100%;
    position: relative;
}

.video {
    width: 100%;
    height: auto;
    display: block;
    max-height: 100%;
}

.video button,
.video video {
    position: relative !important;
    height: auto !important;
    max-height: 100%;
    width: 100%;
    outline: none;
}

video.fullscreen {
    height: 100% !important;
}

.playBtnContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

button.playBtn {
    width: 84px;
    height: 84px !important;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    outline: none;
    transition: 0.5s;
    z-index: 10;
}

.playBtn div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.playBtn:hover {
    background: rgba(0, 0, 0, 0.7);
}

/* Control bar */

.controlBar {
    position: absolute;
    bottom: 0;
    z-index: 1020;
    width: 100%;
    flex-direction: column;
    padding: 4px;

    display: none;
}

.video:hover .controlBar {
    display: flex;
}

.controlBar__timelineContainer {
    width: 100%;
}

.controlBar__timelineContainer input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
}

.controlBar__timelineContainer input[type="range"]:focus {
    outline: none;
}

.controlBar__timelineContainer input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ffffff;
    border-radius: 6px;
    border: 0px solid #000000;
}

.controlBar__timelineContainer input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #7a76ef;
    height: 14px;
    width: 14px;
    border-radius: 25px;
    background: #7a76ef;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
}

.controlBar__timelineContainer input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
}

.controlBar__timelineContainer input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ffffff;
    border-radius: 6px;
    border: 0px solid #000000;
}

.controlBar__timelineContainer input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #7a76ef;
    height: 14px;
    width: 14px;
    border-radius: 25px;
    background: #7a76ef;
    cursor: pointer;
}

.controlBar__timelineContainer input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.controlBar__timelineContainer input[type="range"]::-ms-fill-lower {
    background: #ffffff;
    border: 0px solid #000000;
    border-radius: 12px;
    box-shadow: 0px 0px 0px #000000;
}

.controlBar__timelineContainer input[type="range"]::-ms-fill-upper {
    background: #ffffff;
    border: 0px solid #000000;
    border-radius: 12px;
    box-shadow: 0px 0px 0px #000000;
}

.controlBar__timelineContainer input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #7a76ef;
    height: 14px;
    width: 14px;
    border-radius: 25px;
    background: #7a76ef;
    cursor: pointer;
}

.controlBar__timelineContainer input[type="range"]:focus::-ms-fill-lower {
    background: #ffffff;
}

.controlBar__timelineContainer input[type="range"]:focus::-ms-fill-upper {
    background: #ffffff;
}

.controlBar__buttonsRowContainer {
    display: flex;
    margin: 0 -8px;
}

.controlBar__buttonsRowContainer_block {
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.controlBar__buttonsRowContainer_emptyBlock {
    margin: 0 8px;
    flex: 1;
}

.controlBar__buttonsRowContainer_block button {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    height: 24px;
    padding: 4px;
}

.controlBar__buttonsRowContainer_block button svg {
    height: 16px;
}

.controlBar__buttonsRowContainer_block button.playpause,
.controlBar__buttonsRowContainer_block button.mutesmallbtn {
    width: 40px;
    cursor: pointer;
}

.volume {
    width: 50px;
    display: flex;
    margin-top: -2px;
}

.volume input[type="range"] {
    height: 17px;
    -webkit-appearance: none;
    background: transparent;
    width: 100%;
}

.volume input[type="range"]:focus {
    outline: none;
}

.volume input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ffffff;
    border-radius: 1px;
    border: 0px solid #000000;
}

.volume input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #7a76ef;
    height: 10px;
    width: 10px;
    border-radius: 25px;
    background: #7a76ef;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.5px;
}

.volume input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
}

.volume input[type="range"]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ffffff;
    border-radius: 1px;
    border: 0px solid #000000;
}

.volume input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #7a76ef;
    height: 10px;
    width: 10px;
    border-radius: 25px;
    background: #7a76ef;
    cursor: pointer;
}

.volume input[type="range"]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.volume input[type="range"]::-ms-fill-lower {
    background: #ffffff;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

.volume input[type="range"]::-ms-fill-upper {
    background: #ffffff;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

.volume input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #7a76ef;
    height: 10px;
    width: 10px;
    border-radius: 25px;
    background: #7a76ef;
    cursor: pointer;
}

.volume input[type="range"]:focus::-ms-fill-lower {
    background: #ffffff;
}

.volume input[type="range"]:focus::-ms-fill-upper {
    background: #ffffff;
}

.fullscreenbtn {
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
}

.quality {
    background: #7a76ef;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    margin-right: 8px;
}
