.env {
  position: fixed;
  z-index: 1200;
  background: #FFFFFF;
  padding: 4px;
  border: 1px solid rgba(65, 62, 147, 0.7);
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 900;

  &:active {
    background-color: #C6C6C6;
  }
}