.coment {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: -6px;
}

.coment:last-child {
    margin-bottom: 2px;
}

.img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
}

.coment img {
    object-fit: cover;
}

.overtext {
    display: flex;
    align-items: center;
}

.name {
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px;
    display: flex;
    margin-right: 10px;
}

.userlink {
    color: black;
    text-decoration: none;
    cursor: pointer;
    display: inline;
    min-width: max-content;
    padding-right: 5px;
    font-weight: 600;
    font-size: 15px;
}

.userlink:hover {
    text-decoration: underline;
}

.text {
    display: inline-block;
    color: #656178;
    font-weight: 400;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
}

.showtext {
    color: #48438E;
    font-weight: 600;
    text-decoration: none;
    padding-left: 10px;
    position: relative;
    padding-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 1s;
}

.showtext:after {
    transition: 1s;
    content: '';
    display: inline-flex;
    border: none;
    width: 8px;
    height: 5px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy42NDY0NCA0LjI3MDlMNy4xODIyOSAwLjczNTA1TTAuODIzNTQ3IDAuNzM1MDQ2TDQuMzU5NCA0LjI3MDkiIHN0cm9rZT0iIzQ4NDM4RSIvPgo8L3N2Zz4K");
    background-size: 100%;
    margin-left: 5px;
}

.showtext:hover:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy42NDY0NCA0LjI3MDlMNy4xODIyOSAwLjczNTA1TTAuODIzNTQ3IDAuNzM1MDQ2TDQuMzU5NCA0LjI3MDkiIHN0cm9rZT0iIzdCNzBCRiIvPgo8L3N2Zz4K");
}

.showtext.hidetext:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC4zNTkzOSAwLjczNTAyTDAuODIzNTM5IDQuMjcwODdNNy4xODIyOCA0LjI3MDg3TDMuNjQ2NDMgMC43MzUwMjMiIHN0cm9rZT0iIzQ4NDM4RSIvPgo8L3N2Zz4K");
}

.showtext.hidetext:hover:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC4zNTkzOSAwLjczNTAyTDAuODIzNTM5IDQuMjcwODdNNy4xODIyOCA0LjI3MDg3TDMuNjQ2NDMgMC43MzUwMjMiIHN0cm9rZT0iIzdCNzBCRiIvPgo8L3N2Zz4K")
}

.showtext:hover {
    color: #7B70BF;
}

.name.opencoment {
    flex-direction: column;
}

.name.opencoment .text {
    padding-left: 0;
    max-width: none;
    white-space: normal;
    overflow: visible;
    text-overflow: inherit;
    display: inline;
}

.overtb {
    display: flex;
}

.name.opencoment .overtb {
    display: inline-block;
    margin-top: 2px;
}

@media (max-width: 1023px) {
    .name {
        flex-direction: column;
        width: calc(100% - 20px);
    }

    .name.opencoment .text {
        width: 100%;
    }

    .text {
        padding-left: 0;
        max-width: 100%;
    }

    .overtb {
        align-items: flex-start;
    }

    .overtext {
        width: calc(100% - 30px);
    }
}

.commentAction {
    visibility: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & > div {
        width: fit-content;
        white-space: nowrap;
        height: 18px;
    }

    svg {
        width: auto;
        margin: 0 4px;
        height: 18px;
    }

    &:hover {
        background-color: #413e930a;

        :global(.tooltipText) {
            visibility: visible;
        }
    }
}


.coment:hover .commentAction{
    visibility: visible;
}
