.moreActions {
  position: relative;
  width: 100% !important;
  display: flex;
  justify-content: center;

  &__overlay {
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    width: unset;
    height: unset;
  }

  &__actions {
    z-index: 1101;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 7px 4px 24px rgba(9, 7, 60, 0.1);
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    right: -2px;
    width: max-content;
    top: 36px;

    &_item {
      display: flex;
      align-items: center;
      font-family: "SF Pro Display", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #413E93;
      padding: 8px;

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:hover {
        background-color: #413e930a;
      }

      span {
        margin-left: 8px;
      }
    }

    &_icon {
      height: 24px;
    }

    &_hornIcon{
      height: 18px;
    }
  }
}