body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-display: swap;
    font-family: "SF Pro Display";
    src: url("./assets/fonts/sfpro/SFProDisplay-Regular.eot");
    src: local("SF Pro Display Regular"), local("SFProDisplay-Regular"),
    url("./assets/fonts/sfpro/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sfpro/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/sfpro/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/fonts/sfpro/SFProDisplay-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "SF Pro Display";
    src: url("./assets/fonts/sfpro/SFProDisplay-Semibold.eot");
    src: local("SF Pro Display Semibold"), local("SFProDisplay-Semibold"),
    url("./assets/fonts/sfpro/SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sfpro/SFProDisplay-Semibold.woff2") format("woff2"),
    url("./assets/fonts/sfpro/SFProDisplay-Semibold.woff") format("woff"),
    url("./assets/fonts/sfpro/SFProDisplay-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "SF Pro Display";
    src: url("./assets/fonts/sfpro/SFProDisplay-Bold.eot");
    src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
    url("./assets/fonts/sfpro/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sfpro/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/fonts/sfpro/SFProDisplay-Bold.woff") format("woff"),
    url("./assets/fonts/sfpro/SFProDisplay-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "SF Pro Display";
    src: url("./assets/fonts/sfpro/SFProDisplay-Medium.eot");
    src: local("SF Pro Display Medium"), local("SFProDisplay-Medium"),
    url("./assets/fonts/sfpro/SFProDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sfpro/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/sfpro/SFProDisplay-Medium.woff") format("woff"),
    url("./assets/fonts/sfpro/SFProDisplay-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

html,body {
    height: 100%;
    scrollbar-gutter: stable;
}

#root {
    height: 100%;
    scrollbar-gutter: stable;

}

body {
    font-family: "SF Pro Display", sans-serif;
    background-color: #f2f1f8;
}

.noScroll {
    overflow: hidden;
}

.height100 {
    height: 100% !important;
}

@media (max-width: 1023px) {
    body {
        background: white;
    }
}

li {
    list-style-type: none;
}

input[type="password"] {
    font: small-caption;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__header {
    background-color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background: #3a3b8d;
}

.react-datepicker__navigation--next {
    border-left-color: #3a3b8d;
}

.react-datepicker__navigation--previous {
    border-right-color: #3a3b8d;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #3a3b8d;
    font-family: "SF Pro Display", sans-serif;
    margin-bottom: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-family: "SF Pro Display", sans-serif;
}

.react-datepicker__header {
    border-bottom: none;
}

.react-datepicker__month {
    margin-top: 0;
}

.ScrollbarsCustom.chat-scroll .ScrollbarsCustom-Wrapper {
    width: 100% !important;
    padding-right: 21px;
}

.ScrollbarsCustom.chat-scroll .ScrollbarsCustom-Scroller::-webkit-scrollbar {
    display: none;
}

.ScrollbarsCustom.chat-scroll .ScrollbarsCustom-Scroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ScrollbarsCustom.chat-scroll .ScrollbarsCustom-Scroller {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 3px !important;
    right: -10px !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: #b4b4d2 !important;
}

/*.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {*/
/*    width: 3px!important;*/
/*    right: -10px!important;*/
/*}*/
/*.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {*/
/*    background: #B4B4D2!important;*/
/*}*/
.ScrollbarsCustom-Wrapper {
    inset: 0px !important;
    right: 0 !important;
}

.ChoicePostScroll {
    height: 590px !important;
    margin-bottom: -20px;
}

.ChoicePostMenuScroll {
    border: 1px solid red;
    height: 70px !important;
}

@media (max-width: 1023px) {
    .ChoicePostMenuScroll {
        height: 50px !important;
    }
}

@media (max-height: 767px) {
    .ChoicePostScroll {
        height: 450px !important;
    }
}

@media (max-height: 650px) {
    .ChoicePostScroll {
        height: 350px !important;
    }
}

.ReactModal__Overlay {
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
}

.createpost-modal.ReactModal__Content {
    display: table;
    width: 552px;
    margin: 0 auto;
    border: none !important;
    box-shadow: 0 2px 25px rgb(0 0 0 / 15%);
    padding: 25px 25px !important;
    margin-bottom: 40px;
    margin-top: 40px;
    background: white;
    outline: none;
}

@media (max-width: 1023px) {
    .createpost-modal.ReactModal__Content {
        width: calc(100% - 50px);
        top: 0 !important;
        left: 0 !important;
        margin-top: 0;
        margin-bottom: 0;
    }
}

#money-mod.ReactModal__Content {
    background: transparent !important;
    border: none !important;
    outline: none !important;
}

.tariff_scroll {
    height: 150px !important;
}

.tariff_scroll .ScrollbarsCustom-Content {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1023px) {
    .tariff_scroll {
        height: 160px !important;
    }
}

.tariff_scroll .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
    display: none;
}

#videomodal.ReactModal__Content {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    width: 854px;
    height: 480px;
    overflow: visible !important;
    background: transparent !important;
}

@media (max-width: 1023px) {
    #videomodal.ReactModal__Content {
        width: 100%;
        height: auto;
    }
}

.fanter-select {
    outline: none;
    font-family: "SF Pro Display", sans-serif;
    cursor: pointer;
}

.fanter-select .fanter-select__control {
    border: 1px solid #d1d1e4;
    border-radius: 8px;
    height: 46px;
    box-shadow: none;
}

.fanter-select .fanter-select__control:hover {
    border-color: #d1d1e4;
}

.fanter-select .fanter-select__control--is-focused {
    border: 1px solid #413e93;
}

.fanter-select .fanter-select__control--is-focused:hover {
    border: 1px solid #413e93;
}

.fanter-select .fanter-select__indicator svg {
    fill: #413e93;
}

.fanter-select .fanter-select__menu {
    z-index: 2;
    background: #ffffff;
    box-shadow: 3px 7px 27px rgba(72, 67, 142, 0.11);
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 1px;
    margin-bottom: 0;
}

.fanter-select .fanter-select__option {
    height: 42px;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    color: #413e93;
    font-weight: 600;
}

.fanter-select .fanter-select__option.fanter-select__option--is-focused {
    background: #e8e8f0;
    border-radius: 6px;
    cursor: pointer;
}

.fanter-select .fanter-select__option.fanter-select__option--is-selected {
    background: transparent;
    opacity: 0.5;
    cursor: default;
}

.fanter-select .fanter-select__placeholder {
    color: #74719d;
    font-size: 16px;
    font-weight: 500;
}

.fanter-select .fanter-select__indicator-separator {
    display: none;
}

.fanter-select .fanter-select__value-container {
    padding-left: 16px;
}

.fanter-select .fanter-select__indicator {
    margin-right: 8px;
}

.fanter-select .fanter-select__single-value {
    font-weight: 600;
    color: #2a277c;
    font-size: 16px;
}

.carousel.swiper-container {
    height: 100%;
    width: 100%;
}

.carousel .swiper-button-next,
.carousel .swiper-button-prev {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    transition: 0.5s;
}

.carousel .swiper-button-next {
    display: none;
}

.carousel .swiper-button-prev {
    display: none;
}

.carousel:hover .swiper-button-next {
    display: flex;
}

.carousel:hover .swiper-button-prev {
    display: flex;
}

.carousel .swiper-button-next:after {
    content: "";
    width: 9px;
    height: 14px;
    margin-left: 2px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDkgMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Ljc0MjI0IDcuNDQzMjJDOC43NDIyNCA3Ljc5ODI1IDguNjIxNTcgOC4wMzQ5NCA4LjM4MDI1IDguMjcxNjJMMi41ODgzOSAxMy45NTIxQzIuMTA1NzQgMTQuNDI1NSAxLjM4MTc1IDE0LjQyNTUgMC44OTkxIDEzLjk1MjFDMC40MTY0NDYgMTMuNDc4NyAwLjQxNjQ0NiAxMi43Njg3IDAuODk5MSAxMi4yOTUzTDUuODQ2MzEgNy40NDMyMkwwLjg5OTEgMi40NzI4MUMwLjQxNjQ0NSAxLjk5OTQ0IDAuNDE2NDQ1IDEuMjg5MzggMC44OTkxIDAuODE2MDAzQzEuMzgxNzUgMC4zNDI2MzEgMi4xMDU3NCAwLjM0MjYzIDIuNTg4MzkgMC44MTYwMDNMOC4zODAyNSA2LjYxNDgyQzguNjIxNTcgNi43MzMxNiA4Ljc0MjI0IDcuMDg4MTkgOC43NDIyNCA3LjQ0MzIyWiIgZmlsbD0iIzQxM0U5MyIvPgo8L3N2Zz4K");
    background-repeat: no-repeat;
}

.carousel .swiper-button-prev:after {
    content: "";
    width: 9px;
    height: 14px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDkgMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjI1Nzc2MiA3LjQ0MzIyQzAuMjU3NzYyIDcuNzk4MjUgMC4zNzg0MjYgOC4wMzQ5NCAwLjYxOTc1MyA4LjI3MTYyTDYuNDExNjEgMTMuOTUyMUM2Ljg5NDI2IDE0LjQyNTUgNy42MTgyNSAxNC40MjU1IDguMTAwOSAxMy45NTIxQzguNTgzNTUgMTMuNDc4NyA4LjU4MzU1IDEyLjc2ODcgOC4xMDA5IDEyLjI5NTNMMy4xNTM2OSA3LjQ0MzIyTDguMTAwOSAyLjQ3MjgxQzguNTgzNTUgMS45OTk0NCA4LjU4MzU1IDEuMjg5MzggOC4xMDA5IDAuODE2MDAzQzcuNjE4MjUgMC4zNDI2MzEgNi44OTQyNiAwLjM0MjYzIDYuNDExNjEgMC44MTYwMDNMMC42MTk3NTMgNi42MTQ4MkMwLjM3ODQyNiA2LjczMzE2IDAuMjU3NzYyIDcuMDg4MTkgMC4yNTc3NjIgNy40NDMyMloiIGZpbGw9IiM0MTNFOTMiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
}

.carousel .swiper-button-prev {
    left: 20px;
}

.carousel .swiper-button-next {
    right: 20px;
}

.carousel .swiper-button-disabled {
    pointer-events: initial !important;
}

.carousel .swiper-pagination-bullet {
    background: white;
    width: 5px;
    height: 5px;
    opacity: 0.7;
}

.carousel .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 7px;
    height: 7px;
    opacity: 1;
}

.swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 20px !important;
}

@media (max-width: 1023px) {
    .carousel .swiper-button-next,
    .carousel .swiper-button-prev {
        display: none;
    }
}

.fullscreen.swiper-container {
    background-size: contain;
    position: fixed;
    z-index: 16;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.react-datepicker__day--outside-month {
    color: gray;
}

@media (max-width: 1023px) {
    .react-datepicker__navigation--previous:hover {
        border-right-color: #3a3b8d;
    }

    .react-datepicker__navigation--next:hover {
        border-left-color: #3a3b8d;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.tooltipText {
    visibility: hidden;
    width: max-content;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    background: #ffffff;
    padding: 8px;
    right: 0;
    bottom: 30px;
    font-size: 13px;
    color: #656178;
    height: 12px;
    filter: drop-shadow(0.2px 0.2px 1.4px rgba(124, 118, 162, 0.7));
}

.tooltipText:before {
    z-index: 1;
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    bottom: -2px;
    right: 12px;
    background-color: #fff;
    transform: rotate(22.5deg);
}

@media (max-width: 1023px) {
    .tooltipText {
        visibility: hidden !important;
    }
}
