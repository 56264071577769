.modal {
  outline: none;
  border-radius: 13px !important;
  width: 100%;
  height: 100%;
  background: transparent;
  box-sizing: border-box;
  box-shadow: 3px 3px 2px rgba(65, 62, 147, 0.03);
  overflow: auto;
  padding:  16px 0;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 0 !important;
    background: #FFFFFF;
  }

  @media (max-height: 600px) {
    margin: 0;
    max-height: 100%;
    box-shadow: none;
    border-radius: 0 !important;
  }

  & > div {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    & > div {
      margin: 0;
    }

    @media (max-width: 1023px) {
      padding: 0;
      overflow: hidden;
    }
  }

  .backBtn {
    border: none;
    background-color: transparent;
    position: fixed;
    top: 20px;
    left: 20px;
    font-family: "SF Pro Display", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #39347B;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      margin-right: 8px;
      transform: rotate(180deg);

      svg {
        height: 21px;
      }
    }

    @media (max-width: 1023px) {
      position: unset;
      font-size: 20px;
      margin: 8px;

      &__icon {
        svg {
          height: 14px;
        }
      }
    }
  }
}

.overlay {
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F2F1F8 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  touch-action: none;

  @media (max-width: 600px) {
    align-items: flex-end;
  }

  @media (max-height: 600px) {
    align-items: flex-end;
  }
}
