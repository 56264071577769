.fanter_select {
  outline: none;
  font-family: "SF Pro Display", sans-serif;
  cursor: pointer;
  height: 46px;
  touch-action: none;

  & > div[class$="-control"] {
    cursor: pointer;
    touch-action: none;

    & > div:first-child {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.fanter_select [class$="-control"] {
  border: 1px solid #d1d1e4;
  border-radius: 8px;
  box-shadow: none;
  min-height: auto;
  height: 100%;
}

.fanter_select [class$="-control"]:hover {
  border-color: #d1d1e4;
}

.fanter_select [class$="-menu"] {
  z-index: 2;
  background: #ffffff;
  box-shadow: 3px 7px 27px rgba(72, 67, 142, 0.11);
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 1px;
  margin-bottom: 0;
}

.fanter_select [class$="-option"] {
  height: 42px;
  line-height: 42px;
  padding: 0;
  padding-left: 12px;
  padding-right: 16px;
  color: #413e93;
  font-weight: 600;
  border-radius: 6px;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.fanter_select [class$="-option"]:hover {
  background: #e8e8f0;
  border-radius: 6px;
  cursor: pointer;
}

.fanter_select [class$="-singleValue"] {
  color: #2a277c;
  font-size: 16px;
  font-weight: 600;
}

.fanter_select [class$="-ValueContainer"] {
  padding: 8px 12px;
}

.fanter_select [class$="-indicatorContainer"] {
  margin-right: 8px;
}
