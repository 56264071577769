.videoinfo {
    height: 470px;
    border-radius: 7px;
    background: #242424;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.videoinfo_content {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.videoinfo_tit {
    font-size: 21px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 15px;
    color: white;
}
.videoinfo_text {
    font-size: 15px;
    color: #B8B8B8;
}

.videoinfo_delbtn {
    margin-top: 30px;
}
.videoinfo_delbtn button {
    height: 40px;
}
.videoinfo_delbtn div {
    display: flex;
    order: -1;
}
.videoinfo_delbtn button svg {
    margin-left: 0;
    margin-right: 10px;
}

@media (max-width: 1023px) {
    .videoinfo {
        height: 340px;
        width: calc(100% + 30px);
        margin-left: -15px;
        border-radius: 0;
    }
    .videoinfo_content {
        max-width: 90%;
    }
    .videoinfo_tit {
        font-size: 17px;
        margin-top: 20px;
    }
    .videoinfo_text {
        font-size: 12px;
    }
    .videoinfo_content svg {
        width: 47px;
    }
    .videoinfo_delbtn button svg {
        width: 20px;
    }

}
