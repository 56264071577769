.post {
  width: 100%;
  max-width: 740px;
  margin-top: 20px;
  box-shadow: 0.2px 0.2px 1.4px rgba(124, 118, 162, 0.3);
  background: #ffffff;
  border-radius: 13px;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 1280px) {
    margin-right: 20px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0;
    padding-top: 15px;
    box-shadow: none;
    border-top: 1px solid #e7e7ff;
    margin-top: 0;

    &:last-child {
      border-bottom: none;
    }
  }
}

.post.adv {
  padding-bottom: 20px;
}

.post_isBlocked {
  cursor: default;
}

.post.adv .overtext ul {
  display: none;
}

.post.adv .overtext {
  position: relative;
}

.post.adv .tit {
  margin-right: 200px;
}

.post.paypost .post_img {
  display: none;
}
.contentCenter{
  display:flex;
  justify-content: center;
}

@media (max-width: 1023px) {
  .post.adv .mob_tit {
    margin-top: 0;
  }
}

.post_header {
  min-height: 70px;
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    min-height: 54px;
    margin-top: -16px;
  }
}


.user_info {
  flex: 1;
  margin: 6px 0;
}

.info {
  &__row {
    display: flex;
    align-items: center;
  }
}

.user_info img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.user_info .deflogo {
  width: 54px;
  height: 54px;
  position: relative;
  z-index: 3;
}

.user_info .deflogo img {
  width: 54px !important;
  height: 54px !important;
}

.user_info .user_logo {
  width: 54px !important;
  height: 54px !important;
  border-radius: 50%;
  object-fit: cover;
}

.user_about {
  margin-left: 20px;
}

.about_name {
  display: flex;
  align-items: center;

  a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 264px;
  }
}

.businessApproved {
  margin-left: 8px;
}

.businessApproved,
.businessApproved div,
.businessApproved svg {
  width: 19px;
  height: 19px;
}

.about_name a {
  font-weight: 600;
  font-size: 19px;
  color: black;
  text-decoration: none;
  position: relative;
  z-index: 3;
}

.about_name a:hover {
  color: #413e93;
  text-decoration: underline;
}

.about_time {
  font-size: 13px;
  color: #656178;
  margin-top: 4px;
}

@media (max-width: 1023px) {
  .user_info img {
    height: 38px;
    width: 38px;
  }
  .user_info .user_logo {
    height: 38px !important;
    width: 38px !important;
  }
  .user_info .deflogo {
    width: 38px !important;
    height: 38px !important;
  }
  .user_info .deflogo img {
    width: 38px !important;
    height: 38px !important;
  }
  .user_info .deflogo div {
    width: 38px !important;
    height: 38px !important;
  }
  .about_name a {
    font-size: 15px;
  }
  .about_time {
    font-size: 12px;
    margin-top: 2px;
  }
  .user_about {
    margin-left: 12px;
  }
  .approve svg,
  .edit svg,
  .reply svg {
    width: 23px;
    height: 21px;
  }
}

@media (max-width: 374px) {
  .about_name a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    display: block;
  }
}

.post_img {
  border-radius: 7px;
  margin-bottom: 20px;
}

.post_img img.postimage {
  width: 100%;
  height: 470px;
  border-radius: 7px;
  object-fit: cover;
}

.post.bigheightimg .post_img img.postimage {
  height: 100%;
}

.post.blurheightimg .post_img {
  height: 470px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.post.blurheightimg .post_img img.postimage {
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.post .post_img img.postimage.blurimg {
  display: none;
}

.post.blurheightimg .post_img img.postimage.blurimg {
  display: block;
  object-fit: cover;
  -webkit-filter: blur(15px);
  filter: blur(15px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

@media (max-width: 1023px) {
  .post_img {
    border-radius: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: 12px;
  }
  .post_img img.postimage {
    height: 250px;
    border-radius: 0;
  }
  .post.blurheightimg .post_img {
    height: 250px;
  }
}

.post_paystub {
  height: 470px;
  margin-bottom: 20px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: relative;
}

.post.paypost .post_paystub {
  display: flex;
}

.paypostbg {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.post_paystub div:first-child {
  z-index: 1;
  position: relative;
}

.plus18 {
  display: flex;
  position: absolute;
  right: 15px;
  bottom: 22px;
}

.plus18.plus18PayPost {
  bottom: auto;
  top: 22px;
}

.plus18 span {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 10px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 12px;
  cursor: pointer;
}

.plus18 span:first-child {
  margin-right: -10px;
}

.post_paytit {
  font-size: 26px;
  font-weight: 600;
  color: white;
  margin-top: 20px;
  z-index: 1;
  margin-bottom: 20px;
}

.post_buypaypostBtn {
  display: none;
  width: 100%;
  margin-top: 15px;
}

.post.paypost .post_buypaypostBtn {
  display: flex;
}

@media (max-width: 1023px) {
  .post.paypost .post_paystub {
    height: 250px;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-radius: 0;
  }
  .post_buypayinpostBtn {
    display: none;
  }
  .post_paytit {
    margin-top: 0;
  }
  .paypostbg {
    border-radius: 0;
  }
}

.overtext {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.text_area {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.tit {
  font-size: 19px;
  font-weight: 600;
  padding-bottom: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.titovtext {
  margin-right: 20px;
}

.noimg .post_img {
  display: none;
}

.text {
  color: #656178;
  font-size: 15px;
  width: 490px;
  word-break: break-word;
  line-height: 20px;
  width: 100%;
  margin-top: -8px;
  margin-bottom: 15px;
}

.showtext {
  color: #48438e;
  font-weight: 600;
  text-decoration: none;
  padding-left: 10px;
  position: relative;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 1s;
}

.showtext:after {
  transition: 1s;
  content: "";
  display: inline-flex;
  border: none;
  width: 8px;
  height: 5px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy42NDY0NCA0LjI3MDlMNy4xODIyOSAwLjczNTA1TTAuODIzNTQ3IDAuNzM1MDQ2TDQuMzU5NCA0LjI3MDkiIHN0cm9rZT0iIzQ4NDM4RSIvPgo8L3N2Zz4K");
  background-size: 100%;
  margin-left: 5px;
}

.showtext:hover:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy42NDY0NCA0LjI3MDlMNy4xODIyOSAwLjczNTA1TTAuODIzNTQ3IDAuNzM1MDQ2TDQuMzU5NCA0LjI3MDkiIHN0cm9rZT0iIzdCNzBCRiIvPgo8L3N2Zz4K");
}

.showtext.hidetext:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC4zNTkzOSAwLjczNTAyTDAuODIzNTM5IDQuMjcwODdNNy4xODIyOCA0LjI3MDg3TDMuNjQ2NDMgMC43MzUwMjMiIHN0cm9rZT0iIzQ4NDM4RSIvPgo8L3N2Zz4K");
}

.showtext.hidetext:hover:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC4zNTkzOSAwLjczNTAyTDAuODIzNTM5IDQuMjcwODdNNy4xODIyOCA0LjI3MDg3TDMuNjQ2NDMgMC43MzUwMjMiIHN0cm9rZT0iIzdCNzBCRiIvPgo8L3N2Zz4K");
}

.showtext:hover {
  color: #7b70bf;
}

.coment {
  display: inline-flex;
  margin-top: 13px;
  color: #413e93;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition: 1s;
}

.coment svg path {
  transition: 1s;
}

.coment:hover {
  color: #7b70bf;
}

.coment:hover svg path {
  stroke: #7b70bf;
}

.coment svg {
  width: 24px;
  margin-right: 13px;
}

.coment span {
  padding-top: 2px;
}

@media (max-width: 1023px) {
  .tit {
    font-size: 16px;
    flex-direction: column;
  }
  .text_area {
    flex-direction: column;
  }
  .text {
    padding-right: 0;
    font-size: 14px;
    width: 100%;
  }
  .mob_tit {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    margin-top: 8px;
  }
  .coment {
    font-size: 14px;
  }
}

.post .post_adbbtn {
  display: none;
}

.post.adv .post_adbbtn {
  display: flex;
  position: relative;
  margin-top: -20px;
}

.post.adv .text a {
  display: none;
}

.post.adv .coment {
  display: none;
}

.post.adv .text_area {
  flex-direction: row;
}

.post.adv .text {
  width: 490px;
}

.post.adv .post_comments {
  display: none;
}

.post.adv .addcoment {
  display: none;
}

@media (max-width: 1023px) {
  .post.adv .post_adbbtn {
    margin-top: 0;
    width: 100%;
    margin-top: 15px;
  }
  .post.adv .text_area {
    flex-direction: column;
  }
  .post.adv .text {
    width: 100%;
  }
}

.addcoment {
  background: #eeeef6;
  height: 37px;
  display: flex;
  border-radius: 7px;
  overflow: hidden;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.addcoment input {
  border: none;
  outline: none;
  width: calc(100% - 100px);
  margin-left: 20px;
  background: transparent;
  font-size: 15px;
  font-family: "SF Pro Display", sans-serif;
  color: #413e93;
  font-weight: 500;
}

.addcoment input::placeholder {
  color: #74719d;
  font-weight: 500;
  font-size: 15px;
}

.addcoment button {
  border: none;
  outline: none;
  padding-left: 20px;
  cursor: pointer;
  padding-right: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #413e93;
  font-family: "SF Pro Display", sans-serif;
  background: transparent;
}

.addcoment button:disabled {
  color: #c6c6c6;
}

.videostub {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videostub img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post_videoplay {
  width: 84px;
  height: 84px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: none;
  border: none;
  outline: none;
  transition: 0.5s;
}

.videostub:hover .post_videoplay {
  background: rgba(0, 0, 0, 0.7);
}

.closevideomodal {
  position: fixed;
  top: 5vh;
  right: 3vw;
  cursor: pointer;
  z-index: 17;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closevideomodal div,
.closevideomodal svg {
  width: 15px;
  height: 15px;
}

.closevideomodal {
  background: rgba(0, 0, 0, 0.2);
}

.closevideomodal svg path {
  stroke: white;
}

@media (max-width: 1100px) {
  .closevideomodal {
    right: 0px;
    top: 20px;
  }
}

@media (max-width: 1023px) {
  .post_videoplay {
    width: 60px;
    height: 60px;
  }
  .post_videoplay svg {
    width: 23px;
    margin-left: 5px;
  }
  .closevideomodal {
    right: 20px;
  }
}

.shortbtns {
  margin-left: 12px;
  margin-top: -15px;
}

.topbttns {
  flex: 1;
  display: flex;
  margin: 4px -2px;
  height: 40px;
  justify-content: flex-end;

  .postAction {
    cursor: pointer;
    margin: 0 2px;
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .tooltipText {
      visibility: hidden;
      width: max-content;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 10;
      background: #ffffff;
      padding: 8px;
      right: 0px;
      bottom: 30px;
      font-size: 13px;
      color: #656178;
      height: 12px;
      filter: drop-shadow(0.2px 0.2px 1.4px rgba(124, 118, 162, 0.7));

      &:before {
        z-index: 1;
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        bottom: -2px;
        right: 12px;
        background-color: #fff;
        transform: rotate(22.5deg);
      }
    }

    & > div {
      width: fit-content;
      height: 24px;
    }

    svg {
      width: auto;
      height: 24px;
    }

    &:hover {
      background-color: #413e930a;

      .tooltipText {
        visibility: visible;
      }
    }
  }

  .disabled {
    cursor: not-allowed;
  }

  .disabled svg path {
    stroke: #bfbfca;
  }

  .disabled:hover {
    background-color: #bfbfca0a;
  }

  .approve {
    &:hover {
      background-color: #13a7830a;
    }
  }

  .isApprove {
    background-color: #13a7830a;
    border: 1px solid #13a7832a;
    box-sizing: border-box;
  }

  .displayInMainContent {
    &:hover {
      background-color: #F2F2F7;
    }
  }

  .isDisplayInMainContent {
    background-color: #F2F2F7;
    border: 1px solid #F2F2F7;
    box-sizing: border-box;
  }

  .negative {
    &:hover {
      background-color: #cf142b0a;
    }
  }

  .isNegative {
    background-color: #cf142b0a;
    border: 1px solid #cf142b2a;
    box-sizing: border-box;
  }
}

@media (max-width: 1023px) {
  .shortbtns {
    margin-right: 8px;
    margin-left: 8px;
  }

  .topbttns {
    margin: 4px -1px;
    height: 34px;

    .postAction {
      margin: 0 1px;
      height: 34px;
      width: 34px;
      background: none !important;

      .tooltipText {
        display: none;
      }

      & > div {
        width: fit-content;
        height: 18px;
      }

      svg {
        width: auto;
        height: 18px;
      }
    }
  }
}

.carousel_videoplay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chattext {
  display: flex;
  border-bottom: 1px solid #b4b4d2;
  border-top: 1px solid #b4b4d2;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chattexticon {
  margin-right: 16px;
}

.chtext {
  color: #656178;
  font-size: 15px;
  width: 490px;
  word-break: break-word;
  line-height: 20px;
  width: 100%;
}

@media (max-width: 1023px) {
  .chtext {
    font-size: 14px;
  }
}

.post_tips {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 16;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  touch-action: none;
}

@media (max-width: 1023px) {
  .post_tips {
    border-radius: 0px;
  }
}

.post_priceContainer {
  position: absolute;
  z-index: 1;
  display: flex;
  bottom: 32px;
  right: 22px;
  margin: 0 -4px;

  .post_price {
    margin: 0 4px;
    padding: 8px;
    border-radius: 6px;
    background: #060523B3;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
  }
}

.hidden {
  display: none !important;
}
