.slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.postimage {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
}
.postimage img{
    width: 100%;
    height: 470px;
    border-radius: 7px;
    object-fit: contain;
}

.fullScreen img{
    flex: .9;
    width: unset !important;
    cursor: pointer;
    height: unset !important;
    max-width: 100%;
} 

.blurimg {
    height: 100%;
    display: block;
    object-fit: cover;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.post_videoplay {
    width: 84px;
    height: 84px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    outline: none;
    transition: 0.5s;
    z-index: 10;
}
.post_videoplay div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.post_videoplay:hover {
    background: rgba(0, 0, 0, 0.7);
}

@media (max-width: 1023px) {
    .post_videoplay {
        width: 60px;
        height: 60px;
    }
    .post_videoplay svg {
        width: 23px;
        margin-left: 5px;
    }
    .postimage img{
        height: 250px;
        border-radius: 0 !important;
    }
}