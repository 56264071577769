.banned {
    position: absolute;
    top: 54px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.7);
    z-index: 3;
    border-radius: 13px;
}
.banned_tit {
    background: #2B2B2B;
    color: white;
    border-radius: 8px;
    padding: 7px 16px;
    display: inline-flex;
    top: 56px;
    left: 45px;
    position: absolute;
}
.banned_tit svg {
    margin-right: 10px;
    margin-bottom: -2px;
}
.banned_info {
    background: #2B2B2B;
    color: white;
    border-radius: 8px;
    max-width: 440px;
    margin: 285px auto 0 auto;
    display: flex;
    align-items: center;
}
.banned_message {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    border-right: 1px solid #535353;
}
.banned_message span {
    font-size: 17px;
    font-weight: 600;
}
.banned_message a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 3px;
    font-size: 13px;
    font-weight: 500;
}
.banned_message a:hover {
    text-decoration: none;
}
.banned_delete {
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    min-width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    display: flex;
    cursor: pointer;
}
.banned_delete svg {
    margin-right: 15px;
    margin-top: -2px;
}
@media (max-width: 1023px) {
    .banned {
        padding-left: 15px;
        padding-right: 15px;
    }
    .banned_tit {
        left: 15px;
    }
    .banned_info {
        margin-top: 170px;
        width: auto;
    }
    .banned_message {
        padding-left: 20px;
        padding-right: 20px;
    }
    .banned_message span {
        font-size: 16px;
    }
}
