.modal {
    background-color: white;
    outline: none;
    border-radius: 13px !important;
    width: 100%;
    max-width: 360px;
    height: 312px;
    box-sizing: border-box;

    @media (max-width: 1023px) {
        width: calc(100% - 64px);
        border-radius: 24px;
    }

    @media (max-width: 425px) {
        width: calc(100% - 16px);
        max-width: calc(100% - 16px);
    }

    @media (max-height: 600px) {
        top: 20px;
        bottom: auto;
        margin-bottom: 20px;
    }
}

.overlay {
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    width: 100%;
    height: 100%;
    position: relative;

    &__version{
        position: absolute;
        font-size: 10px;
        line-height: 12px;
        font-family: "SF Pro Display", sans-serif;
        letter-spacing: -0.01em;
        color: #0000006c;
        right: 13px;
        bottom: 6px;
    }

    &__column {
        min-height: 180px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__closeIcon {
        position: absolute;
        right: 19px;
        top: 15px;
        cursor: pointer;
        height: 11px;
        width: 11px;

        svg {
            height: 11px;
            width: 11px;
        }
    }

    &__icon {
        margin-top: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text {
        box-sizing: border-box;
        margin-top: 13px;
        font-family: "SF Pro Display", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #000000;
        width: 100%;
        padding: 0 16px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__description {
        box-sizing: border-box;
        margin: 12px 0;
        font-family: "SF Pro Display", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #78728f;
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: center;
    }

    &__actions {
        flex: 1;
    }

    .actions {
        margin-bottom: 26px;

        &__row {
            height: 100%;
            display: flex;
            margin: 0 -16px;
            justify-content: center;
            align-items: flex-end;
        }

        button {
            margin: 0 16px;
            width: 134px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 8px;
            font-family: "SF Pro Display", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;
        }

        &__tryAgainBtn {
            background-color: #413e93;
            color: #ffffff;

            &:hover {
                background-color: darken($color: #413e93, $amount: 20);
            }
        }

        &__cancelBtn {
            background-color: #e3e3ef;
            color: #413e93;

            &:hover {
                background-color: darken($color: #e3e3ef, $amount: 20);
            }
        }
    }
}
