.iconlist {
    display: flex;
    position: relative;
}
.iconlist svg {
    height: 20px;
}
.iconlist li {
    display: flex;
    margin-right: 20px;
    align-items: center;
}
.iconlist li:last-child {
    margin-right: 0px;
}
.iconlist li button {
    border: none;
    outline: navajowhite;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
}
.iconlist li.likes button:hover svg path {
    stroke: #7b70bf;
    transition: 1s;
}
.iconlist li.likes.active svg path {
    stroke: #cf142b;
    fill: #cf142b;
}
.iconlist li.likes.active button:hover svg path {
    stroke: #cf142b;
}
.iconlist li.likes.active span {
    color: #cf142b;
}
.iconlist li.tipp a:hover svg path {
    fill: #7b70bf;
    transition: 1s;
}

.iconlist li.disabled:hover {
    cursor: default;
}
.iconlist li.disabled button:hover {
    cursor: not-allowed;
}
.iconlist li.disabled svg path {
    stroke: #bfbfca;
}
.iconlist li.disabled button:hover svg path {
    stroke: #bfbfca;
}
.iconlist li.disabled span {
    color: #bfbfca;
}

.iconlist li span {
    margin-left: 8px;
    margin-top: -2px;
    color: #413e93;
    font-size: 12px;
    font-weight: 600;
    min-width: 10px;
    cursor: pointer;
}

.iconlist li span.tips {
    font-size: 15px;
    font-weight: 800;
}

.events {
    cursor: pointer;
}
.overmoney {
    position: absolute;
    z-index: 4;
    bottom: 40px;
    right: -20px;
}
@media (max-width: 1023px) {
    .overmoney {
        bottom: 35px;
        right: auto;
        left: -20px;
    }
    .iconlist {
        width: auto;
        justify-content: flex-start;
        margin-top: 0;
        order: -1;
        padding-top: 0;
        margin-bottom: 4px;
    }
    .iconlist li {
        margin-right: 16px;
    }
    .iconlist li span {
        font-size: 11px;
        min-width: 8px;
        margin-left: 6px;
    }
    .iconlist li span.tips {
        font-size: 13px;
    }
    .iconlist li svg {
        width: 24px;
    }
}
