.userAvatar {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        object-fit: cover;
    }

    span{
        // text-decoration: none;
    }

    .businessApproved {
        position: absolute;
        z-index: 2;
        top: 0.5%;
        left: 2%;
        width: 25%;
        height: 25%;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .status {
        height: 13px;
        transform-origin: 50% 7px;
        transform: rotate(225deg);
        position: absolute;

        & > div {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            border: 2px solid white;
        }

        &_available > div {
            background-color: #0b9e62;
        }

        &_justNow > div {
            background-color: #f9d249;
        }
    }
}
