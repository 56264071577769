.fullscreen {
    width: 100%;
    height: 100%;
}

.fullscreen img {
    width: 100% !important;
    height: 100% !important;
}

.fullscreen div {
    height: 100%;
}

.tools {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: 10px;
}

@media (max-width: 768px) {
    .tools {
        max-width: 90vw;
        justify-content: center;
    }
}

/*.quickPinchZoomContainer {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.fullscreen img {*/
/*    width: unset !important;*/
/*    height: unset !important;*/
/*    max-width: 100%;*/
/*    max-height: 100vh;*/
/*    display: block;*/
/*    margin: auto;*/
/*}*/