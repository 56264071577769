.container {
    //height: 100%;
    /*overflow: hidden;*/
    display: flex;
    flex-direction: column;
}

.fallback{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerStub{
    height: 78px;
}

.privateContent {
    flex: 1;
    display: flex;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;

    @media (max-width: 1023px) {
        min-height: 100%;
    }
    &.isHeader{
        min-height: calc(100vh - 78px);
        @media (max-width: 1023px){
            min-height: calc(100% - 115px);
        }
    }
}

.mobileMenuStub{
    height: 65px;
    display: none;
}

@media (max-height: 768px) and (max-width: 1280px) {
    .headerStub {
        height: 53px;
    }
}

@media (max-width: 1023px) {
    .headerStub {
        height: 50px;
    }

    .privateContent {
        flex-direction: column;
        background: white;
    }

    .mobileMenuStub{
        height: 65px;
        display: block;
    }
}
