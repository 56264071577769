.post_img {
    border-radius: 7px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}
.post_img img.postimage {
    width: 100%;
    height: 470px;
    border-radius: 7px;
    object-fit: cover;
}
.post.bigheightimg .post_img img.postimage {
    height: 100%;
}
.post.blurheightimg .post_img {
    height: 470px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.post.blurheightimg .post_img img.postimage {
    object-fit: contain;
    position: relative;
    z-index: 1;
}
.post .post_img img.postimage.blurimg {
    display: none;
}
.post.blurheightimg .post_img img.postimage.blurimg {
    display: block;
    object-fit: cover;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
.paypostbgdiv {
    width: 100%;
    cursor: default;
}
.paypostbottom {
}
@media (max-width: 1023px) {
    .post_img {
        border-radius: 0;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-bottom: 12px;
    }
    .post_img img.postimage {
        height: 250px;
        border-radius: 0;
    }
    .post.blurheightimg .post_img {
        height: 250px;
    }
}
.paypostLock {

}
.paidcontent {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.paydlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
}
.paydtit {
    font-size: 25px;
    font-weight: 600;
    color: white;
    margin-top: 10px;
    z-index: 1;
    margin-bottom: 20px;
}
.payPlus {
    display: flex;
    position: absolute;
    right: 15px;
    bottom: 22px;
    bottom: auto;
    top: 22px;
}
.payPlus span {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    display: flex;
    background: rgba(0,0,0,0.7);
    color: white;
    font-size: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 12px;
}
.payPlus span:first-child {
    margin-right: -10px;
}

.blurimg.blurimg40 {
    filter: blur(40px)!important;
}
.paybtns {
    width: calc(100% - 60px);
    position: absolute;
    bottom: 55px;
}

@media (max-width: 1023px) {
    .paydlock {
        margin-top: 0;
    }
    .paydtit {
        margin-bottom: 0;
        font-size: 18px;
    }
}
