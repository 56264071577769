.fModal {
  max-width: 700px;
}

.content {
  width: 100%;
  height: 100%;

  &__column {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: auto;
    touch-action: none;
  }

  &__reason {
    margin: 30px 0;
    font-family: "SF Pro Display", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: #61617E;
    text-align: center;
  }

  &__loader {
    height: 66px;
    margin: 0 auto;

    div {
      top: 16px;
    }
  }

  &__btn {
    background-color: #413E93;
    border-radius: 8px;
    border: none;
    width: 100%;
    cursor: pointer;
    font-family: "SF Pro Display", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    padding: 12px;
    margin-top: 30px;

    color: #FFFFFF;

    &:hover {
      background-color: darken(#413E93, 20);
    }

    &:disabled {
      cursor: default;
      background-color: lighten(#413E93, 30);
    }
  }

  .header {
    &__row {
      display: flex;
      align-items: center;
      position: relative;
    }

    &__icon {
      width: 36px;
      height: 36px;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    &__closeIcon {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__text {
      font-family: "SF Pro Display", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      color: #2f2693;
      margin-left: 8px;
    }
  }

  form {
    margin-top: 34px;
    height: 100%;
  }
}
