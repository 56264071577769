.modal {
    background-color: white;
    outline: none;
    border-radius: 13px !important;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    box-shadow: 3px 3px 2px rgba(65, 62, 147, 0.03);
    max-height: 80%;
    display: flex;
    touch-action: none;

    @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;
        margin: 0;
        max-height: calc(100% - 40px);
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    @media (max-height: 600px) {
        margin: 0;
        max-height: calc(100% - 40px);
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.overlay {
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    touch-action:none;

    @media (max-width: 600px) {
        align-items: flex-end;
    }

    @media (max-height: 600px) {
        align-items: flex-end;
    }
}

.modalContent {
    flex: 1;
    width: 100%;
    padding: 36px 60px;
    box-sizing: border-box;
    position: relative;
    touch-action: none;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 600px) {
        padding: 16px 20px;
    }

    @media (max-height: 600px) {
        padding: 16px 20px;
    }

    &__closeIcon {
        position: absolute;
        z-index: 1001;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    &__column {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin: 0 -50px;
        padding: 0 50px;

        @media (max-width: 600px) {
            margin: 0 -30px;
            padding: 0 30px;
        }

        @media (max-height: 600px) {
            margin: 0 -30px;
            padding: 0 30px;
        }
    }

    &__header {
        display: flex;
        margin-top: -3px;
        margin-bottom: 26px;
        font-size: 18px;
        font-weight: 500;
        font-family: "SF Pro Display", sans-serif;
        max-width: calc(100% - 24px);
    }

    &__content {
        flex: 1;
        width: 100%;
        overflow: hidden;
    }

    &__actions {
        padding: 8px 16px;
    }

    .actions {
        &__row {
            display: flex;
            margin: 0 -8px;
            justify-content: flex-end;
        }

        &__btn {
            margin: 0 8px;
        }
    }
}
