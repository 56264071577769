.overlay {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.tips {
    padding: 25px 35px;
    box-shadow: 0px 4px 64px rgba(99, 97, 162, 0.2);
    border-radius: 16px;
    background: white;
    width: 290px;
    z-index: 5;
}
.tips > form {
    height: 100%;
}
.t_header {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    min-height: 25px;
}
.content {
    min-height: 150px;
}
.aftersend {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.send_status {
    margin-bottom: 20px;
}
.mt10 {
    margin-top: 10px;
}
.send_status svg {
    /*width: 82px!important;*/
    height: auto;
}
.balance {
    font-size: 14px;
    color: #73739b;
    margin-bottom: 20px;
    font-weight: 500;
}
.aftertit {
    font-size: 20px;
    font-weight: 600;
    width: 180px;
    text-align: center;
    margin-bottom: 25px;
}
.t_close {
    cursor: pointer;
    position: absolute;
    right: -15px;
    top: -15px;
}
.t_quick {
    font-size: 11px;
    color: #74719d;
    margin-top: 5px;
    margin-bottom: 10px;
}
.t_btns {
    display: flex;
    justify-content: space-between;
}
.t_btns div {
    width: 63px;
    margin-bottom: 12px;
}
.t_btns div button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    justify-content: center;
}
.t_inp {
    margin-bottom: 12px;
    position: relative;
}
.t_error {
    position: absolute;
    bottom: -6px;
    left: 14px;
    color: red;
    background-color: white;
    padding: 0 4px;
    font-size: 12px;
}
.t_sendbtn button {
    width: 100%;
    justify-content: center;
}
.select {
    margin-top: 25px;
    margin-bottom: 10px;
}

@media (max-width: 1023px) {
    .tips {
        max-width: 310px;
        margin: 0 auto;
        padding: 15px;
    }
    .t_close {
        right: 0;
        top: -8px;
    }
}

.loaderContainer {
    margin-top: -25px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
